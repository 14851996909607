@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700;900&display=swap');
body {
    font-family: 'Poppins', sans-serif;
    background-color: #ffffff;
    font-size: 14px;
    background-attachment: fixed;
}

a {
    color: #10A0E3;
}
a:hover, a:focus {
    color: #25a9b8;
}

hr {
    margin: 2rem 0;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.125)
}

.hr-m1{
    margin: 0.8rem 0;
}

.btn{
    text-transform: uppercase;
    font-size: 12px;
}

.main-cont {
    padding: 3.4rem 7rem 0 5rem;
}

.main-cont-3 {
    padding: 3.4rem 7rem;
}

.main-cont-2 {
    padding: 2rem 3rem;
}

.main-cont-plink{
    padding: 2rem 2.5rem 0rem 2.5rem;
}

.full-w {
    width: 100%;
}

.btn-group-2 .btn {
    width: 50%;
}

.btn {
    font-weight: bold;
}

.btn-lg {
    font-size: 15px;
    padding: 16px;
}

p.small-grey, label.small-grey{
    color: #b9b7b7;
    font-size: 12px;
    margin: 10px 0 20px 0;
    line-height: 18px;
    cursor: text;
}

label.grey, .grey{
    color: #b9b7b7;
}

.dark-grey{
    color: #606060;
}

h6.dark-grey{
    letter-spacing: 0;
}

.small-grey{
    color: #b9b7b7;
    font-size: 12px;
}

.small-small{
    font-size: 11px!important;
}

.ml-4{
    margin-left: 40px;
}

.ml-3{
    margin-left: 30px;
}
.mr-3{
    margin-right: 30px;
}

.ml-2{
    margin-left: 20px!important;
}
.mr-2{
    margin-right: 20px;
}

.inline{
    display: inline-block;
}

.inline-block{
    display: inline-block;
}

p.small-grey-m0{
    color: #b9b7b7;
    font-size: 12px;
    margin: 0;
}

.m0{
    margin: 0!important;
}

p .small-grey-title{
    color: #b9b7b7;
    font-size: 15px;
    margin: 10px 0 20px 0;
}

/* btn colors */

.btn-primary, .btn-primary:focus , .btn-outline-primary:focus { 
    background-color: #10A0E3;
    border: solid 1px #10A0E3;
    color: #ffffff;
}

.btn-primary:hover, .btn-outline-primary:hover {
    background-color: #10A0E3;
    border: solid 1px #10A0E3;
}

.btn-outline-light {
    border-color: rgba(0, 0, 0, 0.06);
    color: #606060;
}

.btn-outline-light:hover {
    border-color: rgba(0, 0, 0, 0.06);
    color: #606060;
}

.btn-outline-primary{
    border-color: #10A0E3;
    color: #10A0E3;
}


.btn-success{
    background: #10A0E3;
    background: -webkit-radial-gradient(top left, #10A0E3, #0982C7);
    background: -moz-radial-gradient(top left, #10A0E3, #0982C7);
    background: radial-gradient(to bottom right, #10A0E3, #0982C7);
    border: none;
}

.btn-success:hover{
    background-color: #0982C7;
}

.btn-success:active, .btn-success:focus{
    box-shadow:0 0 0 .3rem rgba(0, 0, 0, 0.1)!important;
    background-color: #0982C7;
}

.btn-danger{
    background-color: rgb(255, 100, 82);
    border-color: rgb(255, 100, 82);
}


/* end btn colors */

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    letter-spacing: -0.6px;
    color: #333333;
}



.form-floating>.form-control, .form-floating>.form-select {
    height: 45px;
}

.form-floating>label {
    padding: 10px .75rem;
    font-size: 14px;
}

.form-floating-money>label {
    padding: 12px .75rem 8px 30px;
    font-size: 14px;
    overflow: hidden;
    line-height: 19px;
    text-align: left;
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.75) translateY(-.5rem) translateX(.15rem);
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 10px;
    padding-bottom: 0;
}

.popover-body input.form-control, .popover-body select.form-control{
    font-size: 13px!important;
    height: 33px;
}

.popover-body-2 input.form-control, .popover-body-2 select.form-control{
    font-size: 13px!important;
    height: 40px;
}

.popover-body-2 select.form-control{
    padding-top: .7rem!important;
}
.popover-body-2 .line-item .input-group-qty{
    right: 10px;
    top: 9px;
}

textarea.form-control{
    font-size: 13px!important;
}

select {
    height: 45px;
    font-size: 14px!important;
}

.form-control:focus {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05);
}

.form-control.input-error:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 5px rgba(220, 53, 69, 0.1);
}

.card{
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.05);
}

.card-v2{
    border-color: rgba(0, 0, 0, 0.1);
    border: solid 13px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    padding: 1rem 1rem;
}

.card-v3{
    border-color: rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border-radius: 5px;

    padding: 1rem 1rem;
}

.card-v4{
    border-color: rgba(0, 0, 0, 0.1);
    border: solid 1px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border-radius: 5px;
    padding: 1rem 1rem;
}


.card-in {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

.header-brand {
    max-width: 132px;
    margin-bottom: 20px;
}

.header-brand-nm {
    max-width: 140px;
    margin: 0;
}

.progress-steps div{
    background-color: #e3e2e2;
    border: solid 3px #ffffff;
    height: 10px;
}

.progress-steps div.active{
    background-color: #10A0E3;
}

.card-info-badge{
    position: absolute; 
    top: -5px; 
    right: -1px;
    font-weight: normal;
}

.card-brands img{
    margin-left: 6px;
    height: 11px;
}

.card-brands img.amex{
    height: 14px;
}

.card-brands img.discover{
    height: 13px;
}

.card-brands img.mastercard{
    height: 15px;
}

.input-calendar, .input-cc, .input-user, .input-password{
    background-repeat: no-repeat;
    background-position: right 10px top 8px;
    background-size: 25px 25px;
}

.input-calendar{
    background-image: url('../images/calendar.svg');
    background-size: 22px 22px;
    background-position: right 10px top 8px!important;
    font-size: 12px !important;
}
.popover-body .input-calendar{
    background-size: 15px 15px;
    background-position: right 10px top 10px;
}

.input-cc{
    background-size: 25px!important;
    padding-left: 45px!important;
    background-position: left 10px center!important;
}

.input-cc-cont label{
    margin-left: 34px;
}

.visa .input-cc{
    background-image: url('../images/visa.svg');
    background-size: 28px!important
}

.maestro .input-cc{
    background-image: url('../images/maestro.svg');
}

.jcb .input-cc{
    background-image: url('../images/jcb.svg');
}

.mastercard .input-cc{
    background-image: url('../images/mastercard.svg');
}

.discover .input-cc{
    background-image: url('../images/discover.svg');
    background-size: 32px!important;
}

.american-express .input-cc{
    background-image: url('../images/amex.svg');
    background-size: 22px 22px!important;
}

.diners-club .input-cc{
    background-image: url('../images/dinersclub.svg');
    background-size: 24px 24px;
}

.unknown .input-cc, .unionpay .input-cc{
    background-image: url('../images/credit-card.svg');
    background-size: 24px 24px;
}

.input-user{
    background-image: url('../images/avatar.svg');
    background-size: 25px 25px;
}

.input-password{
    background-image: url('../images/asterisk.svg');
    background-size: 25px 25px;
}



@media (max-width: 576px) { 
    .main-cont{
        padding: 30px!important;
    }
}

.sub-header{
    padding: 0;
}

.sub-header-line{
    padding: 0px 0 12px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.review-total{
    font-size: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.review-total .col:last-child{
    text-align: right;
}

.review-total .total-amount .col:last-child{
    font-size: 22px;
    text-align: right;
    font-weight: bold;
}

.review-total .total-amount .col:first-child{
    padding-top: 10px;
}

.top-border-grey{
    padding-top: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}


.form-floating>.form-control, .form-floating>.form-select, .input-lg {
    height: 45px;
}

.bottom-border-grey{
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

.text-left{
    text-align: left;
}

.bottom-grey{
    padding: 20px;
    background-color: rgb(249,249,249);
}

.icon-signal{
    max-width: 3.5rem;
    margin: 10px 0 16px 0;
}

input.big-input{
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ced4da;
    font-size: 16px!important;
}

input.big-input:focus {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

.icon-edit{
   color: #10A0E3;
   font-size: 20px;
   cursor: pointer;
}

.icon-edit.right{
    position: absolute;
    right: 3rem;
}

ul.sortable, ul.sorted{
    list-style: none;
    padding-left: 0;
}

.sortable li, .sorted li{
    background-color: #ffffff;
    padding: 10px 10px 10px 40px;
    background-repeat: no-repeat;
    background-position: left center;
}

.payment-categories .sortable li{
    padding-right: 60px;
    margin-left: -40px;
    margin-right: -60px;
    position: relative;
}

.payment-categories .sorted li{
    padding-left: 10px;
}

.sortable-config li{
    padding: 6px 4px 0px 28px;
    margin-left: -28px;
}

.sortable li:hover{
    background-image: url('../images/bx-grid-vertical.svg');
}

.ui-sortable-helper{
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    0px 0px 10px 5px rgba(0,0,0,0.1);  /* Firefox 3.5 - 3.6 */
    box-shadow:         0px 0px 10px 5px rgba(0,0,0,0.1); 
}



.popover{
    max-width: 400px;

}

.popover-body{
    min-width: 380px;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
  }
  
  .popover .arrow::before, .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }





  .bs-popover-top{
      inset: auto auto 0px 0px!important;
  }


  .bs-popover-top, .bs-popover-auto[x-placement^="top"] {
    margin-top: 0.5rem;
  }
  
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px);
  }
  
  .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff;
  }









  .bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
  }
  
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }
  
  .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
  }
  
  .bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
  }
  
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px);
  }
  
  .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
  }

  .popover-footer{
      border-top: 1px solid rgba(0,0,0,.2);
      margin: 15px -1rem 0 ;
      padding: 12px 1rem 0 1rem;
  }

  .popover-body .btn.btn-light{
    font-size: 10px;
  }

  .popover-footer .btn, .btn.bordered{
      margin-left: 8px;
      color:#000;
      background-color:#f9fafb;
      border-color:#f9fafb;
      box-shadow:0 0 0 .25rem rgba(211,212,213,.5);
      border: none;
  }

.btn.bordered{
    color:#000;
    background-color:#f9fafb;
    border-color:#f9fafb;
    box-shadow:0 0 0 .25rem rgba(211,212,213,.5);
    border: none;
}

.text-normal{
    font-weight: normal;
    text-transform: none;
}

.btn-sm.bordered{
    padding: 2px 12px;
}
  
  .popover-footer .btn:focus{
      background-color:rgba(211,212,213,.25)
  }

  .btn.bordered:focus, .btn.bordered:hover{
        background-color:rgba(211,212,213,.25)
  }

  .popover-body label.header, #right-panel label.header{
      font-weight: bold;
      margin-bottom: 6px;
      display: block;
  }

  .icheck-primary label, .popover-body label{
    font-size: 12px;
  }
  
  .popover-body .small-grey{
    font-size: 11px;
      margin-top: -4px;
      margin-bottom: 5px;
  }

  .card-in {
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
}

.card-hover:hover{
    border-color: #10A0E3;
    cursor: pointer;
}

.card-body.card-header{
    background-color: #ffffff;
    border: none;
    cursor: pointer;
}

.grey-icon{
    color: rgba(0, 0, 0, 0.2);
}

.grey-icon-v2{
    opacity: 0.4;
}

.opacity-panel{
    opacity: 0.3;
    overflow: hidden;
}

.accordion-right-corner{
    right: 12px;
    top: 7px;
    position: absolute;
}

.alert{
    padding: 12px 1rem;
}

.payorFieldsConfig .icheck-primary{
    margin-top: 0!important;
}

.payorFieldsConfig small{
    font-size: 10px;
}

.payorFieldsConfig .row .p0{
    padding: 0 15px 0 0!important
}

.symbol{
    font-size: 20px;
}

.payorFieldsConfig .remove-icon-btn, .category-icon .remove-icon-btn{
    width: 23px;
    height: 23px;
    cursor: pointer;
    background-color: transparent;
    background-image: url('../images/trash.svg');
    background-size: 17px 17px;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
}

.payorFieldsConfig .remove-icon-btn:disabled, .payorFieldsConfig .remove-icon-btn[disabled]{
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    filter: alpha(opacity=40);
    opacity: 0.4;
}

.bs-popover-right, .bs-popover-bottom{
    box-shadow: 0 0 0 5000px rgba(255,255,255,0.52);
    
}

.bs-popover-bottom{
    min-width: 680px;
}

.bs-popover-bottom .popover-body{
    min-width: 478px;
}

.cc-form input{
    border: none;
    font-size: 16px;
}

.cc-form input.form-control:focus{
    -webkit-box-shadow: none;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    none;  /* Firefox 3.5 - 3.6 */
    box-shadow:         none; 
}

.cc-form{
    border: 1px solid #ced4da;
    border-radius: .25rem;
    margin: 0;
}

.cc-form .cc-field{
    border: none;
    padding: 0;
}

.cc-form .cc-field input{
    padding: 0;
}

.cc-form .cc-field label{
    padding-left: 0;
    padding-right: 0;
}

.cc-form .cc-field:first-child label{
    padding-left: 12px;
}

.search-input{
    background-image: url('../images/bx-search-alt.svg');
    background-repeat: no-repeat;
    background-position: right 7px center;
    padding-right: 40px!important;
}
.loading{
    background-image: url('../images/loadinfo.gif')!important;
}

.payment-categories{
    margin: 0 6rem;
}

.payment-categories .category-icon{
    right: 4px;
    padding-top: 0.5rem;
    text-align: right;
    position: absolute;

}
.payment-categories .remove-icon-btn{
    margin-left: 3px;
}

.form-control:disabled, .form-control[readonly]{
    background-color: #fff;
}

.input-money{
    background-image: url('../images/bx-dollar.svg');
    background-repeat: no-repeat;
    background-position: left 7px top 15px;
    background-size: 15px;
    padding-left: 25px!important;
}

.input-money-v2{
    background-image: url('../images/bx-dollar.svg');
    background-repeat: no-repeat;
    background-position: left 7px center;
    background-size: 15px;
    padding-left: 25px!important;
}

.popover-body .input-money{
    background-position: left 7px top 8px;
}

.optional-pay{
    position: absolute; 
    background-color: #fff;
    z-index: 1;
    right: 66px;
    top: 16px;
}

.input-group-qty{
    width: 64px;
    position: absolute;
    z-index: 1;
    right: 71px;
    top: 22px;
}

.input-group-qty-mr{
    right: 104px!important;
}

.input-group-qty .btn{
    padding: 0 4px;
    background-color: #fff;
    border: 1px solid #ced4da;
    height: 22px;
}

.input-group-qty .btn:hover{
    background-color: #e6e6e6;
}

.input-group-qty input.form-control{
    height: 22px;
    padding: 0;
    text-align: center;
}

.input-group-qty input.form-control:focus{
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.big-amount{
    width: 100%;
    border: none;
    color:#333333;
    text-align: center;
    background: transparent;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: -1px;
    margin: -15px 0 5px 0;
    border-bottom: solid 1px transparent;
}

.big-amount:focus{
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #cccccc;
    outline: none;
}

.icheck-primary>input:first-child:checked+input[type=hidden]+label::before, .icheck-primary>input:first-child:checked+label::before{
    background-color: #10A0E3;
    border-color: #10A0E3;;
}

.text-transform-normal{
    text-transform: none;
}

.main-top-bar{
    border-bottom: solid 1px #e7e6e6;
    background-color: #ffffff;
    left: 0;
    right: 0;
    position: fixed;
    padding: 0 0 0 235px;
    z-index: 10;
    top: 0;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.02);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.02);
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 50%, rgba(228,226,226,1) 51%, rgba(255,255,255,1) 52%);
    -webkit-transition: all 0.4s ease;
    -moz-transition:all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.main-top-bar-single{
    background: rgb(255,255,255)!important;
}

.main-left-bar{
    border-right: solid 1px #e7e6e6;
    background-color: #ffffff;
    padding: 16px 0px;
    position: fixed;
    width: 235px;
    height: 100%;
    z-index: 11;
    text-align: left;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.03);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.03);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.03);
    left: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition:all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.main-left-bar.open{
    left: -235px;
}

.main-left-bar ul a{
    font-size: 12px;
    padding: 0 44px 0 68px;
    margin: 13px 0 13px 1px;
    color: #5a6a80!important;
    cursor: pointer;
    -webkit-transition: all 0.8s ease;
    -moz-transition:all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.8s ease;
    border-right: 2px solid transparent;
    display: block;
    border-left: 4px solid transparent;
    text-decoration: none;
}

.main-left-bar ul a:hover, .main-left-bar ul li.selected a, .menu-accordion-body a.selected{
    border-left: 4px solid #10A0E3;
    color: #000000!important;
}

.main-left-bar ul li{
    list-style: none;
    font-size: 11px;
}

.main-left-bar ul li .header{
    margin-left: 50px;
    font-size: 11px;
    color: #414b59;
}

.main-left-bar ul li .icon{
    font-size: 15px;
    display: inline-block;
    margin-top: -4px;
    position: absolute;
    left: 48px;
    -webkit-transition: all 0.4s ease;
    -moz-transition:all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}


.main-left-bar ul{
    padding-left: 0;
}

.main-body{
    padding: 0 44px 0 279px;
    -webkit-transition: all 0.4s ease;
    -moz-transition:all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.main-body.open-menu{
    padding: 0 44px 0 44px;
}

.mt-body{
    padding: 9rem 0 4rem;
}

.mt-body2{
    padding: 11rem 0 4rem;
}

.mt-body3{
    padding: 4.5rem 0;
}

.mt-body4{
    padding: 8rem 0;
}

.mt-body5{
    padding: 5rem 0;
}

.main-menu-bottom{
    bottom: 0;
    position: absolute;
}

.main-top-bar .btn{
    margin: 0 5px;
}

.main-top-bar .btn:last-child{
    margin-right: 0;
}
.main-top-bar .btn:first-child{
    margin-left: 0;
}

#main-branding{
    text-align: center;
    
}

#main-branding img{
    width: 100px;
    margin: 12px 0 34px;
    -webkit-transition: all 0.2s ease;
    -moz-transition:all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    opacity: 1;
}

.main-top-bar .top-bar-sub{
    
    padding: 14px 30px;
}

.main-top-bar .top-bar-sub a:first-child{
    margin-left: 0;
}

.main-top-bar .top-bar-sub a:last-child{
    margin-right: 0;
}

.main-top-bar .top-bar-sub a{
    padding: 0;
    margin: 0 8px;
    color: #333333;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition:all 0.5s ease;
    -o-transition: all 0.5s ease;
    font-size: 12px;
    text-decoration: none;
}

a.no-underline{
    text-decoration: none;
}

.main-top-bar a.icon svg{
    font-size: 21px!important;
}

.main-top-bar .profile-name{
    display: inline-block;
    margin-right: 20px;
    font-size: 13px;
}

.main-top-bar a.icon{
    margin: 0 6px!important;
    cursor: pointer;
}

.main-top-bar .top-bar-sub a svg{
    font-size: 17px;
    vertical-align:middle;
}

.main-top-bar .top-bar-sub a:hover{
    color: #008bce;
}

.main-top-bar .top-bar-sub input{
    height: 28px;
    font-size: 13px;
}

#div-loading-layer{
    color: #c5cbd3;
}

#div-loading-layer svg{
    animation: rotation 2s infinite linear;
}

#main-loading-layer{
    z-index: 1061;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,1);
    text-align: center;
    color: #cccccc;
    font-size: 36px;
    top: 0;
    left: 0;
}


#main-loading-layer svg{
    animation: rotation 2s infinite linear;
}

.table{
    font-size: 12px;
}

.table th{
    border-bottom-color: #dee2e6!important;
}

.table .dropdown .btn{
    line-height: 10px;
}

.table .dropdown a, .actions-btn-cont a{
    font-size: 12px;
}

.table .dropdown a:active, .dropdown-menu a:active, .dropdown-item:active{
    background-color: #10A0E3;
    color: #ffffff!important;
}

.dropdown-menu .dropdown-item-text:hover{
    background-color: rgba(234,236,239, 0.8);
}

.dropdown-menu .filter-form.dropdown-item-text:hover{
    background-color: rgb(255,255,255);
}

.table .dropdown .dropdown-toggle::after, .actions-btn-cont .dropdown-toggle::after{
    display: none;
}

.actions-btn-cont .dropdown button:focus{
    box-shadow:none;
}

.badge.bg-success{
    background-color: #88c952!important;
}

.badge.bg-danger{
    background-color: rgb(255, 100, 82)!important;
}

.dropdown button{

}

.payment-icon{
    width: 34px;
}

.card.card-in-border{
    border: solid 8px rgb(0, 0, 0, 0.05)
}

.powered-by-pp-button-preview{
    font-size: 11px;
    color: #b9b7b7;
    margin-top: 10px;
}

.powered-by-pp-button-preview img{
    width: 45px;
    margin-left: 3px;
}

.payment-button-preview .btn{
    padding-left: 40px;
    padding-right: 40px;
}

.color-picker{
    position: absolute!important;
    height: 38px!important;
    padding: 0!important;
    border: none!important;
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important;
    background-color: transparent!important;
}

.color-picker span{
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important;
}

.color-picker-input{
    padding-left: 55px;
}

#pp-color-preview{
    text-align: center;
    padding: 2.48rem 3rem;
}

#pp-color-preview .button-cont{
    background-color: #ffffff;
    padding-bottom: 10px;
    
}

#pp-color-preview .button{
    color: #ffffff;
    font-size: 7px;
    padding: 5px;
    margin: 10px;
}

.toast-success-container{
    background-color: rgb(136, 201, 82)!important;
}

.toast-error-container{
    background-color: rgb(255, 100, 82)!important;
}

.toast-warning-container{
    background-color: rgb(255, 190, 82)!important;
}

input.input-error, select.input-error, textarea.input-error, .component-input-error{
    border-color: #dc3545!important;
}

.cc-form.input-error{
    border-color: #dc3545;
}

.icon-modal{
    font-size: 60px;
    margin-bottom: 15px;
    color: #cccccc;
}

.modal-body{
    padding: 30px 40px;
}

.btn-back{
    color: #333333;
    position: absolute;
    margin: -30px 0 0 -56px;
}

.top-msg-container{
    position: fixed;
    background-color: rgba(47,47,51,1);
    padding: 4px 10px;
    color: #ffffff;
    z-index: 40;
    top: 0;
    font-size: 12px;
    letter-spacing: 0.2px;
}

.bg-decorator{
    height: 50%;
    width: 100%;
    position: fixed;
    opacity: 0.1;
}

.pagination .page-link{
    border: none;
    color: #333333;
}

.link-default{
    font-size: 13px;
    text-decoration: none;
    font-weight: normal;
    margin: 0 10px;
}

.pagination li:first-child .page-link, .pagination li:last-child .page-link{
    color: #10A0E3;
}

.datatable-actions button{
    margin-left: 20px!important;
    text-transform: none;
    font-weight: normal!important;
}

.datatable-actions .dropdown{
    display: inline-block;
}

.datatable-actions .dropdown button{
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 0.25rem rgb(211 212 213 / 50%);
    border: none;
    padding: .375rem .75rem;
}

.datatable-actions .dropdown a, 
.datatable-actions .dropdown label, 
.datatable-actions a.dropdown-item, 
table th .dropdown label{
    font-size: 12px!important;
    cursor: pointer;
    font-weight: normal;
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
}

.datatable-actions .dropdown label input,
table th .dropdown label input{
    margin-right: 4px;
}

.datatable-actions .dropdown .dropdown-toggle::after{
    display: none;
}



.datatable-actions svg{
    font-size: 14px;
    margin-right: 2px;
}

a.selected{
    color: #10A0E3!important;
}

#right-panel, #vterminal-rigth-panel{
    position: fixed;
    top: 0;
    right: -534px;
    width: 534px;
    background-color: #fff;
    height: 100%;
    z-index: 30;
    border-left: solid 1px #e7e6e6;
    padding: 65px 30px 30px;
    -webkit-transition: right 0.3s ease;
    -moz-transition: right 0.3s ease;
    -o-transition: right 0.3s ease;
    transition: right 0.3s ease;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.03);
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.03);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.03);
    overflow-y: auto;
}


#right-panel.open, #vterminal-rigth-panel.open{
    right: 0;
}

#right-panel-close{
    position: absolute;
    font-size: 18px;
    color: rgba(0,0,0,0.7);
    cursor: pointer;
    top: 30px
}

.table>:not(caption)>*>*{
    padding: .8rem .5rem;
}

#right-panel h5.header{
    font-size: 16px;
    letter-spacing: 0;
    padding-bottom: 12px;
    border-bottom: solid 1px #e7e6e6;
}

.right-panel-card img{
    height: 23px!important;
}

#right-panel .btn-group button{
    text-transform: capitalize;
}
#right-panel .btn-group button svg{
    font-size: 18px;
}

.head-filter button{
    margin: 0!important;
}

.head-filter button h6{
   display: inline-block;
   margin-right: 5px;
   font-size: 16px;
   margin-bottom: 0;
}

.head-filter .btn-check:focus+.btn, .head-filter .btn:focus, table th .btn:focus{
    box-shadow: none;
}

table th .btn{
    text-transform: none;
    padding-left: 0;
}

.head-filter button{
    padding-left: 0;
}

table th button{
    padding: 0!important;
}

table .dropdown-menu, .datatable-actions .dropdown-menu{
    margin-top: 5px;
    z-index: 9;
}

table .dropdown-menu label.header{
    font-size: 11px!important;
    margin: -2px 0 2px;
    font-weight: normal;
    display: block;
}

table .dropdown-menu p.small{
    font-size: 12px;
    margin: 0;
    font-weight: normal;
}

.filter-badges .badge{
    margin-right: 10px;
    background-color: #10A0E3;
    padding: 2px 8px;
    display: inline-block;
    cursor: pointer;
}

.filter-badges .badge svg{
    font-size: 16px;
}

.dropdown-menu .form-control{
    font-size: 12px;
}

.form-control{
    height: 38px;
}

.form-select, .form-control{
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
}

.dropdown-menu .form-control.search-enter{
    background-image: url('../images/bx-subdirectory-left.svg');
    background-repeat: no-repeat;
    background-position: center right 3px;
    background-size: 18px;
    padding-right: 26px;
}

table th .search .dropdown-menu{
    min-width: 18rem;
}

.filter-form-datepicker .react-datepicker, .filter-form-datepicker .react-datepicker__month-container{
    width: 100%;
}

.filter-form-datepicker .react-datepicker{
    border: none;
    margin: -.5rem 0;
    font-weight: normal;
}

.filter-form-datepicker.dropdown-item-text{
    padding: 0;
}

.filter-form-datepicker .react-datepicker__header{
    padding-top: 10px;
    border-radius: 0!important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background-color: rgba(0,0,0, 0.15)!important;
    color: #333333!important;
}

.green-text{
    color: #92d050;
}

.red-text{
    color: rgb(255, 100, 82);
}

.blue-text{
    color: #10A0E3;
}

.icon-side-text{
    font-size: 24px;
    margin: -3px 6px 0 6px;
}

h6.sub-header-line.dark-grey{
    font-size: 15px;
}

.dashboard-items label{
    font-size: 12px;
    color: #606060;
}

.dashboard-color-circle{
    float: left;
    margin-right: 15px;
    text-align: center;
    font-size: 34px;
    width: 60px;
    height: 60px;
    padding-top: 2px;
    margin-top: -5px;
    border-radius: 50px;
}

.dashboard-color-circle.orange{
    background-color: rgb(255,238,226);
    color: rgb(253,119,47);
}

.dashboard-color-circle.green{
    background-color: rgb(223,248,231);
    color: rgb(44,185,84);
}

.dashboard-color-circle.red{
    background-color: rgb(254,192,192);
    color: rgb(254,44,46);
}

.dashboard-color-circle.blue{
    background-color: rgb(170,190,225);
    color: rgb(16,105,182);
}

.dashboard-color-circle.purple{
    background-color: rgb(196,143,250);
    color: rgb(100,43,146);
}

.dashboard-color-circle.greenoutline{
    background-color: #ffffff;
    color: rgb(43,184,83);
    border: solid 2px rgb(43,184,83);
    padding-top: 0px;
}

.dashboard-color-circle.yellowoutline{
    background-color: #ffffff;
    color: #ffc107;
    border: solid 2px #ffc107;
    padding-top: 0px;
}

.card-no-border{
    border: none;
    box-shadow: none;
}

.card-no-border .card-body{
    padding: 0;
}
.card-no-border .card-header{
    padding: 0;
    background: 0;
    border: none;
    cursor: pointer;
}

.card-no-border svg{
    display: inline-block;
    margin-right: 0px;
    margin-left: -18px;
}

.info-message-card{
    margin: 15px 0 30px 0!important;
    text-align: center;
}

.ul-nomargin{
    padding-left: 0;
}

.ul-nomargin li{
    list-style: none;
}

.vterminal-tabs{
    margin: -3.4rem -7rem 0rem -7rem!important;
}

.vterminal-tabs a{
    border-radius: 0!important;
    padding: 20px;
    border: none!important;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1)!important;
}


.vterminal-tabs .nav-link.active{
    border-bottom: solid 1px #ffffff!important;
    color: #209ad4;
}

.vterminal-tabs .nav-item:first-child a{
    border-right: solid 1px rgba(0, 0, 0, 0.1)!important;
}

.vterminal-tabs .nav-link{
    background-color: #f2f2f2;
    color: #757070;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1)!important;
}

.search-dropdown-menu{
    position: absolute;
    width: 100%;
    margin-top: -4px;
    padding: .5rem 0;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    background-color: #fff;
    z-index: 10;
}

.search-dropdown-menu .row-card .img-cont img{
    
    width: 70%;
    height: auto!important;
}

.line-item .input-group-qty{
    right: 15px;
    top: 12px;
}

.dropdown-item .dropdown-item{
    padding-left: 0;
}

.popover-body .form-floating label{
    padding-top: 8px!important;
}

.btn-bordered-success{
    background-color: #10A0E3!important;
    font-weight: normal;
    color: #ffffff;
    text-transform: none;
    box-shadow: 0 0 0 .25rem #0ca1e24f;
    -webkit-box-shadow: 0 0 0 .25rem #0ca1e24f;
    -moz-box-shadow: 0 0 0 .25rem #0ca1e24f;
}

.btn-bordered-success:hover, .btn-bordered-success:active, .btn-bordered-success:visited{
    color: #ffffff!important;
}

.card-payment-method{
    width: 410px;
    height: 255px;
    margin: 0 25px 25px 0!important;
    padding: 2rem;
    display: inline-block;
    vertical-align: top;
}

.card-payment-method .datatable-actions .dropdown button{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding-top: 0;
    padding-right: 0;
}

.card-payment-method .datatable-actions .dropdown button svg{
    font-size: 20px;
    color: #10A0E3;
}

.card-payment-method-add{
    border: dashed 1px rgba(0, 0, 0, 0.3)!important;
    padding-top: 60px;
    border-radius: 8px;
}

.btn-dropdown-top-menu{
    display: inline-block;
}

.btn-dropdown-top-menu .dropdown-menu{
    margin-top: 5px;
}

.btn-dropdown-top-menu button{
    display: inline-block;
    margin: 0 6px!important;
    padding: 0;
}

.btn-dropdown-top-menu button:focus{
    box-shadow: none;
}

.btn-dropdown-top-menu button svg{
    font-size: 20px;
}

.btn-dropdown-top-menu a{
    font-size: 12px!important;
    cursor: pointer;
    font-weight: normal;
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
    padding:.25rem 1rem!important;
    margin-left: 0!important;
}
.btn-dropdown-top-menu a:hover{
    color: #333333!important;
}

.table-icon svg{
    font-size: 25px;
    margin: -5px 0;
}

.trending-up{
    color: rgb(36, 193, 143);
    font-weight: bold;
    margin-right: 5px;
    font-size: 12px;
}

.trending-down{
    color: rgb(255, 77, 79);
    font-weight: bold;
    margin-right: 5px;
    font-size: 12px;
}


.box-center{
    display: flex;
  align-items: center;
  justify-content: center;
}

.chart-center{
    position: absolute;
    z-index: 10;
}

.chart-tab .nav-tabs{
    border: none;
}

.chart-tab .nav-tabs .nav-item a{
    border: none;
    color: #333333;
    padding: 12px 20px;
    font-size: 14px;
}

.chart-tab .nav-tabs .nav-item a:hover{
    box-shadow: rgb(146 153 184 / 15%) 0px 15px 30px;
    background-color: #fff;
}

.chart-tab .nav-tabs .nav-item a.active{
    background-color: rgb(248, 249, 251);
}

.chart-tab .trending-up{
    font-weight: normal;
    font-size: 11px;
}

.fl-capitalize{
    text-transform: capitalize;
}

.profile-image{
    border: dashed 1px #333;
    padding: 15px 24px;
    display: inline-block;
    font-size: 30px;
    cursor: pointer;
}

.root-page{
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgb(244,245,247);
}

.card-login{
    background-color: #ffffff;
    webkit-box-shadow: 0px 0px 62px 0px rgb(0 0 0 / 7%);
    box-shadow: 0px 0px 62px 0px rgb(0 0 0 / 7%);
    padding: 60px 80px;
    border-radius: 5px;
    /*min-width: 480px;*/
}

.right-icon-on-input{
    position: absolute;
    top: 12px;
    right: 14px;
    font-size: 20px;
    cursor: pointer;
}

.css-1okebmr-indicatorSeparator{
    display: none;
}

.gautocomplete div[class$="-control"]{
    border-color: none!important;
    box-shadow: none!important;
}

div.input-error .gautocomplete div[class$="-control"]{
    border-color: #dc3545!important;
}

.gautocomplete div[class$="-control"]:hover{
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05);
}

.gautocomplete input{
    width: 100%!important;
}

.hamburgerButton{
    font-size: 22px;
    position: absolute;
    right: -65px;
    top: 14px;
    cursor: pointer;
    color: #555555;
}

.hide{
    display: none;
}

.sm-screen-topbar-menu a{
    margin: 0 18px!important;
}

.sm-screen-topbar-menu a svg{
    font-size: 20px!important;
}

#vterminal-container{
    width: 50em;
}

.report-container{
    width: 100%;
    overflow: auto;
    min-height: 380px;
}

.main-menu .menu-accordion-body{
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
}

.main-menu .menu-accordion-body a:first-child{
    margin-top: 0;
}

.main-menu .menu-accordion-body a{
    padding-left: 73px;
    padding-right: 20px;
    font-size: 11px;
}

.menu-accordion-body-popover a{
    font-size: 12px;
    display: block;
    margin: 6px 0;
    color: #5a6a80!important;
    text-decoration: none;
}

.menu-accordion-body-popover a:hover{
    color: #000000!important;
}

.loginBrand{
    max-width: 110px;
    margin-bottom: 25px;
}

#main-branding-icon{
    text-align: right;
    width: 100%;
}

#main-branding-icon img{
    width: 32px;
    margin: 12px 0 34px;
    top: 10px;
    right: 130px;
    position: absolute;
    -webkit-transition: all 0.4s ease;
    -moz-transition:all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    opacity: 0;
}

.payment-page-name input:disabled {
    background-color: #eeeeee!important;
}

.react-switch-label {
    vertical-align: middle;
    margin-left: 4px;
}

.password-strength-meter-progress {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
  }
  
.password-strength-meter-progress::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
}

.password-strength-meter-label {
    font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
    background-color: #F25F5C;
}
.strength-Fair::-webkit-progress-value {
    background-color: #FFE066;
}
.strength-Good::-webkit-progress-value {
    background-color: #247BA0;
}
.strength-Strong::-webkit-progress-value {
    background-color: #70C1B3;
}

.page-item.active .page-link{
    background-color: #10A0E3;
    border-color: #10A0E3;
    border-radius: 2px;
}

.coming-soon{
    width: 70em;
}

.coming-soon img{
    max-height: 300px;
    max-width: 100%;
    margin-bottom: 30px;
}

.coming-soon .img-container{
    height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

#main-container{
    width: 100%;
    display: flex;
    padding: 25px 38px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.card-brands.accordion-right-corner{
    display: block;
}

.closeModalButton{
    position: fixed;
    background-color: #ffffff;
    border-radius: 3px;
    height: 26px;
    width: 26px;
  
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    color: #6c757d;
    top: 7px;
    right: 6px;
    -webkit-box-shadow: -10px 10px 10px -2px #ffffff; 
    box-shadow: -10px 10px 10px -2px #ffffff;
  }

.bottom-fixed{
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    left: 0;
    padding: 1rem 3rem 2rem 3rem;
    -webkit-box-shadow: 0px -2px 26px 24px #ffffff; 
    box-shadow: 0px -2px 26px 24px #ffffff;
    z-index: 2;
}


.fullw-440{
    position: relative;
}

.first-upper:first-letter {
    text-transform:capitalize;
}

.payabliPaymentForm label{
    margin-bottom: 4px;
}
.popover-body{
    font-size: 13px;
}

.optionalLabelInfo{
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 14px;
    font-size: 10px;
}


#main-container.lightbox .cardExpirationDateContainer label, #main-container.lightbox .cardCvvContainer label, #main-container.lightbox .cardZipcodeContainer label{
    margin-left: -3px;
}

#main-container.lightbox .input-cc{
    background-position: left 10px center!important;
    padding-left: 46px!important;
}

#main-container.lightbox .discover .input-cc{
    background-size: 30px!important;
}

.refreshIcon{
    font-size: 23px;
    margin-left: 6px;
    -webkit-animation:spin 3s linear infinite;
    -moz-animation:spin 3s linear infinite;
    animation:spin 3s linear infinite;
    color: #606060;
}

#cardNumberContainer .form-floating label{
    margin-left:34px;
}

.overflowWrap{
    overflow-wrap: break-word;
}

.inputLabelLimitSize{
    width: 100%;
    display:inline-block;
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
}

.errorMessageComponentPopover .popover-body{
    padding: 2px 6px;
}

.react-datepicker .react-datepicker__header button {
    border: solid 1px #cccccc;
}

.react-datepicker .react-datepicker__header select {
    border: solid 1px #cccccc;
    height: 25px;
}

.react-datepicker-popper .linkCalendar {
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    text-decoration: underline;
}

.react-datepicker-popper{
    z-index: 10!important;
}

.react-datepicker-wrapper{
    width: 100%;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(-360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(-360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(-360deg); 
        transform:rotate(-360deg); 
    } 
}

@media (max-width:1330px){
    .hide-md{
        display: none;
    }
    .show-md{
        display: block!important;
    }

    .coming-soon{
        width: 50em;
    }
}

@media (max-width:960px){
    .show-sm{
        display: block!important;
    }
    .hide-sm{
        display: none!important;
    }

    #vterminal-container{
        width: 40em;
    }

    #vterminal-container .main-card-body{
       padding: 20px 10px;
    }

    .vterminal-tabs{
        margin: -3.4rem -4rem 0rem -4rem!important
    }

    .main-top-bar{
        padding: 0;
    }

    .coming-soon{
        width: 100%;
    }
}

@media (max-width:576px){
    .main-top-bar .profile-name{
        display: none;
    }
    #vterminal-container{
        width: 32em;
    }

    #vterminal-container .main-card-body{
        padding: 20px 10px;
     }
 
     .vterminal-tabs{
         margin: -2rem -2rem 0rem -2rem!important
     }
    
     .cc-form .cc-field:first-child{
        width: 100%;
        border-bottom: solid 1px #ced4da;
        border-right: none
    }

    .cc-form .cc-field{
        width: 33.333%;
        border-right: solid 1px #ced4da;
    }

    .cc-form .cc-field:last-child{
        border-right: none
    }
    
    .cc-form .cc-field input{
        padding: 0 10px;
    }

    .cc-form .cc-field label{
        padding-left: 10px;
    }
    
}


@media (max-width:440px){
    .fullw-440{
        width: 100%!important;
    }
}


@media (max-width:480px){
    .card-login{
        width: 395px!important;
        padding: 40px 50px;
    }

    #vterminal-container{
        width: 23em;
    }
}



@media (max-width:450px){
    .card-brands.accordion-right-corner{
        display: contents;
    }
}

@media (min-width:450px){
    .card-brands.accordion-right-corner{
        display: block;
    }
}

@media (max-width:350px){
    .payabliPaymentForm .row .mb-3{
        width: 100%;
    }
}

@media (min-width:960px){
    .main-left-bar.open{
        left: -170px;
    }

    .main-body.open-menu{
        padding: 0 44px 0 110px;
    }

    .main-left-bar.open .main-menu ul li a, .main-left-bar.open .main-menu-bottom ul li a{
        padding-right: 0;
        color: #ffffff!important;
        
    }

    .main-left-bar.open .main-menu ul li a .icon, .main-left-bar.open .main-menu-bottom ul li a .icon{
        color: #5a6a80!important;
        left: 195px;
    
    }

    .main-left-bar.open #main-branding-icon img{
        opacity: 1;
        right: 15px;
    }
    
    .main-left-bar.open #main-branding img{
        opacity: 0;
    }

    .main-body.open-menu .main-top-bar{
        padding: 0 0 0 65px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

